import Swiper, {Pagination, Autoplay} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

export class Slider
{
    constructor()
    {
        this.sliders = document.querySelectorAll('.c-slider--on')
        if (!this.sliders) {
            return;
        }
        this.init()
    }

    init()
    {
        this.sliders.forEach(s => {
            const swiper = new Swiper(s, {
                modules: [Pagination, Autoplay],
                slidesPerView: 3,
                spaceBetween: 40,
                autoplay: {
                delay: 4000,
                },
                // centeredSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    }
                }
            });
        })
    }
}
