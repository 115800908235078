import { CountUp } from 'countup.js';
import 'waypoints/lib/noframework.waypoints';

export class Counter {

  constructor() {

    const milestones = document.querySelector('.s-stats')

    if(milestones) {
      let thus = this;
      new Waypoint({
        element: milestones,
        handler: function () {
          thus.countStart()
          this.destroy()
        },
        offset: '50%'
      });
    }

  }

  countStart() {
    const counters = document.querySelectorAll(".c-stat__count")

    let options = {
      useEasing: true,
      useGrouping: true,
      separator: "",
      decimal: "."
    };

    counters.forEach( (item) => {
      const value = item.dataset.value;
      const counter = new CountUp(item, value, options);
      counter.start();
    });
  }



}
