import '@css/main.scss';
import '@js/bootstrap';
import {Slider} from '@js/components/Slider';
import {Header} from '@js/components/Header';
import {Modal} from '@js/components/Modal';
import { Loader } from '@js/utils/loader';
import {Counter} from '@js/components/Counter';

import AOS from 'aos';
import 'aos/dist/aos.css';

const scripts = [

];

window.onload = () => {
    AOS.init();
    new Slider()
    new Header()
    new Modal()
    new Loader().load(scripts)
    new Counter()
}
