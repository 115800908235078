export class Modal
{
    constructor()
    {
        document.querySelectorAll("*[toggle-modal]").forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault()
                const href = el.getAttribute('href');

                if (href) {
                    history.pushState({}, null, href);
                }

                const id = el.getAttribute('toggle-modal');
                const content = el.getAttribute('toggle-modal-content');

                const value = el.getAttribute('toggle-modal-value');
                const value_endpoint = el.getAttribute('toggle-modal-value-endpoint');

                if (value && value_endpoint) {
                    const endpoint = document.querySelector(value_endpoint);
                    endpoint.value = value
                    endpoint.dispatchEvent(new window.Event('change', {bubbles: true}));
                }

                const modal = document.querySelector(id);

                if (modal) {
                    modal.showModal();
                    if (content) {
                        modal.innerHTML = content
                    }

                    modal.addEventListener("click", evt => {
                        if (evt.target === modal) {
                            modal.close();
                        }
                    });
                }
            })
        })
    }
}
